<template>
  <MianLayout />
</template>

<script>
import MianLayout from './views/MianLayout.vue';


export default {
  name: 'App',
  components: {
    MianLayout
  }
}
</script>
<style>
</style>
